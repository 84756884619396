/* eslint-disable arrow-body-style */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RootScopeAdapter } from '@providers/root-scope-adapter/root-scope-adapter';
import { BehaviorSubject, combineLatest, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';

import { CountryService } from '../country/country';
import { DeviceKS } from '../device-ks/device-ks';
import { KSFirebase } from '../firebase/ksfirebase';
import { LocationService } from '../helpers/location';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { Platform } from '../platform/platform';
import { PremiumCheckerService } from './premium-checker.service';
import { SessionService } from './session.service';
import { User } from './user';

@Injectable()
export class AuthTokenService {
  public token$ = new BehaviorSubject(null);

  /**
   * Subscripción del launchEvent
   */
  protected launchDataSubscription: Subscription | undefined;

  constructor(
    private metrics: MetricsService,
    @Inject(DOCUMENT) private doc: Document,
    private country: CountryService,
    public deviceks: DeviceKS,
    private sessionFirebase: KSFirebase,
    private session: SessionService,
    private location: LocationService,
    private localStorage: LocalStorage,
    private platform: Platform,
    private rootScopeAdapter: RootScopeAdapter,
    private translate: TranslateService,
    private userService: User,
    private premiumChecker: PremiumCheckerService
  ) {}

  load(): Promise<any> {
    // const request=this.injector.get(REQUEST);
    // console.log('antes de resolver',request.cookies);
    return new Promise(resolve => {
      const customToken = this.location.getUrlParameter('token');
      const newSession = this.location.getUrlParameter('newSession');

      if (newSession) {
        this.removeTokens();
      }

      if (customToken) {
        this.localStorage.setItem('tokenUser', customToken);
      }

      const activeFastPlay = this.location.getUrlParameter('fastPlay');
      if (activeFastPlay) {
        this.rootScopeAdapter.activeFastPlay = true;
      }

      this.resolveSession()
        .pipe(take(1))
        .subscribe(() => {
          // console.log('session casi lista para resolverse', this.doc.readyState, res);

          const launchEvent = this.deviceks.devicePlatform.lauchEvent.value;
          console.log('launch event', launchEvent);
          this.session.syncLaunchDataInFirestore(launchEvent).catch(error => {
            console.error('error al sincronizar launchEvent', error);
          });

          resolve(true);
        });
    });
  }

  resolveSession() {
    // this.metrics.sendEvent('callResolveSession');
    const combined = combineLatest([
      this.country.country,
      this.deviceks.DUID,
      // this.deviceks.devicePlatform.lauchEvent,
    ]).pipe(
      filter(([country, tv_id]) => !!country && !!tv_id),
      distinctUntilChanged((previous, current) => previous[0] === current[0] && previous[1] === current[1]),
      take(1),
      switchMap(async ([, tv_id]) => {
        // // TODO: Check if has launched from mobile
        const launchEvent = this.deviceks.devicePlatform.lauchEvent.value;
        //   //TODO: checkear si es que hay un usuario diferente al que esta logueado
        if (launchEvent?.tokenUser) {
          const mobileTokenUser = launchEvent.tokenUser;
          const tvTokenUser = await this.localStorage.getItem('tokenUser');
          if (!!tvTokenUser && mobileTokenUser !== tvTokenUser) {
            // check TV token user
            const tvPromise = this.premiumChecker.check(tvTokenUser);
            const mobilePromise = this.premiumChecker.check(mobileTokenUser);

            await Promise.all([tvPromise, mobilePromise]).then(async ([tvResponse, mobileResponse]) => {
              //movil premiun to a tv free
              if (!tvResponse.premium && mobileResponse.premium) {
                await this.localStorage.setItem('tokenUser', mobileTokenUser);
                this.localStorage.removeItem('tokenTv');
              }

              //movil free to a tv free
              // no esta redirigiendo
              if (!tvResponse.premium && !mobileResponse.premium) {
                await this.localStorage.setItem('tokenUser', mobileTokenUser);
                await this.localStorage.setItem('isCastFreeUsers', true);
                this.localStorage.removeItem('tokenTv');
              }
            });
            return tv_id;
          }
          return tv_id;
        }

        return tv_id;
      }),
      switchMap(tv_id => {
        return this.session.initSession(tv_id as string).pipe(
          tap(async session => {
            //TODO: SOLO SI fue LANZADO POR MOBILE
            // escribir documento en firebase para saber que el usuario ha iniciado sesion desde un dispositivo movil
            // con el codigo QR de la sesion resuelta

            // se espera a que inicialize el usuario antes de suscribirse a firebase
            console.log('se hace init user ');
            await this.userService.initUser(session);
            console.log('se hizo init user ');
            this.sessionFirebase.init();
            console.log('se hizo init firebase ');
          }),
          catchError(this.errorInitSession.bind(this))
        );
      })
    );
    return combined;
  }

  errorInitSession(error) {
    console.error('🚧 Error', error); // DEBUG 🚧

    if (this.platform.isBrowser()) {
      this.translate.use(this.translate.currentLang || this.translate.getBrowserLang());
      this.translate.get(['APP_NOT_AVAILABLE', 'RETRY']).subscribe(msgs => {
        this.userService.messages = msgs;
        const modalAux = document.getElementById('modalAux');
        (window as any).onRetry = this.userService.onRetry.bind(this.userService);
        (window as any).doReload = this.userService.doReload.bind(this.userService);
        if (modalAux) {
          modalAux.innerHTML =
            `<p class="error-on-init"><br/><span class="face-sad">:(</span><br/><br/>` +
            `<br/>${this.userService.messages['APP_NOT_AVAILABLE']}</p><br/><br/>` +
            `<button id="retry-btn" class="btn btn-retry-reload">` +
            `${this.userService.messages['RETRY']}</button>`;
        }
        this.userService.sessionError = true;
        const loader = document.getElementsByTagName('smart-router-animation-wrap')[0];
        if (loader) {
          loader.remove();
        }
        const modalInits = document.getElementsByClassName('modal-init');
        let i: number;
        for (i = 0; i < modalInits.length; i++) {
          (modalInits[i] as HTMLElement).style.display = 'none';
        }
        const buttonRetry = document.getElementById('retry-btn');
        buttonRetry.addEventListener('click', this.userService.doReload.bind(this));
        buttonRetry.focus();
        document.addEventListener('keypress', this.userService.onRetry);
        document.addEventListener('keydown', this.userService.onRetry);
        setTimeout(() => {
          try {
            const loader2 = document.getElementsByTagName('smart-router-animation-wrap')[0];
            loader2.remove();
            (loader2 as HTMLElement).style.display = 'none';
          } catch (e) {}
        }, 1000);
      });
      return throwError(error);
    }
    return throwError(error);
  }

  removeTokens() {
    this.localStorage.removeItem('country');
    this.localStorage.removeItem('session');
    this.localStorage.removeItem('tokenTv');
    this.localStorage.removeItem('tokenUser');
    this.localStorage.removeItem('selectedGenres');
  }

  removeAllStorage() {
    this.removeTokens();
    this.localStorage.removeAll();
  }
}

export const authTokenFactory =
  (service: AuthTokenService): (() => Promise<any>) =>
  () =>
    service.load();
