import { Injectable } from '@angular/core';
import { Notifications } from '@providers/providers';
import { interval, Observable, Subject } from 'rxjs';

interface TrackChange {
  trackId: string;
  completed: boolean;
  previousBuffer: number;
  newBuffer: number;
}

@Injectable()
export class PlayerTracksService {
  audioTrackList: Subject<any[]> = new Subject();
  // trackChangeCompleted: Subject<{ trackId: string; completed: boolean }> = new Subject();
  trackChangeCompleted: Subject<TrackChange> = new Subject();

  constructor(private notification: Notifications) {}

  private getBufferRangeIndex(player: any): number {
    const buffered = player.buffered();
    for (let i = 0; i < buffered.length; i++) {
      if (buffered.start(i) <= player.currentTime() && buffered.end(i) > player.currentTime()) {
        return i; // Devuelve el índice del segmento de buffer actual
      }
    }
    return -1; // No hay un segmento de buffer que cubra el tiempo actual
  }

  private renderBufferVisualization(player: any): string {
    const totalWidth = 50;
    const duration = player.duration() || 1;
    const bufferEnd = player.buffered().length > 0 ? player.buffered().end(0) : 0;
    const bufferRatio = Math.min(bufferEnd / duration, 1);
    const bufferBlocks = Math.round(bufferRatio * totalWidth);

    return '"' + 'x'.repeat(bufferBlocks) + '-'.repeat(totalWidth - bufferBlocks) + '"';
  }

  private getCurrentBufferRange(player: any): { start: number; end: number } {
    const buffered = player.buffered();
    const currentTime = player.currentTime();
    for (let i = 0; i < buffered.length; i++) {
      if (buffered.start(i) <= currentTime && buffered.end(i) > currentTime) {
        return { start: buffered.start(i), end: buffered.end(i) };
      }
    }
    return { start: 0, end: 0 };
  }

  private clearBufferPartially(player: any, removeAfter: number) {
    try {
      const sourceBuffer = player.tech().sourceBuffer;
      if (sourceBuffer && sourceBuffer.length > 0) {
        console.log('Clearing buffer partially', sourceBuffer.length, sourceBuffer);
        for (const buffer of sourceBuffer) {
          if (buffer.buffered.length > 0 && buffer.buffered.end(0) > removeAfter) {
            buffer.remove(0, removeAfter);
          }
        }
      }
    } catch (error) {
      console.warn('Partial buffer clearing is not supported by this player instance.', error);
    }
  }

  private forceAudioTrackChange(player: any) {
    const audioTracks = player.audioTracks();

    // Encontrar la nueva pista habilitada
    const newTrack: any = Array.from(audioTracks).find((t: any) => t.enabled);
    if (!newTrack) {
      console.warn('No se encontró una nueva pista de audio activa.');
      return;
    }

    console.log(`Cambiando a la nueva pista: ${newTrack.label}`);

    // Verificar si hay buffer suficiente para hacer la transición
    const mediaSource = player.tech().hls.mediaSource;
    const audioBuffer = [...mediaSource.sourceBuffers].find(sb => sb.mimeType?.startsWith('audio'));

    if (audioBuffer) {
      const currentTime = player.currentTime();
      console.log(`Manteniendo buffer de audio hasta ${currentTime}`);

      // Evitar eliminar segmentos que puedan servir para la transición
      if (!audioBuffer.updating) {
        audioBuffer.remove(0, currentTime - 1); // 🔥 Borra solo lo más viejo, dejando lo reciente
      } else {
        audioBuffer.addEventListener(
          'updateend',
          () => {
            audioBuffer.remove(0, currentTime - 1);
          },
          { once: true }
        );
      }
    }

    console.log('Cambio de pista completado sin cortar el audio.');
  }

  private checkBufferAndComplete(player: any, audioTrackId: string, previousBuffer: number) {
    // player.currentTime(player.currentTime() + 0.01);

    this.forceAudioTrackChange(player);

    this.trackChangeCompleted.next({
      trackId: audioTrackId,
      completed: true,
      previousBuffer,
      newBuffer: 0,
    });
  }

  monitorBufferProgress(player: any) {
    if (!player) {
      return;
    }

    interval(1000).subscribe(() => {
      if (player.buffered().length > 0) {
        const currentTime = player.currentTime();
        const bufferLength = player.buffered().length;
        const bufferEnd = player.buffered().end(0);
        const bufferRemaining = bufferEnd - currentTime;
        console.log(player.buffered());
        console.log(
          `Buffer Info - Current Time: ${currentTime}, BL: ${bufferLength} Buffer End: ${bufferEnd}, Buffer Remaining: ${bufferRemaining}`
        );
        console.log(`Buffer Visualization: ${this.renderBufferVisualization(player)}`);
      }
    });
  }

  changeCurrentAudioTrack(player: any, audioTrackId: string): Observable<TrackChange> {
    if (!player) {
      throw new Error('Player is not available');
    }

    const audioTrackList = player.tech().audioTracks();
    if (!audioTrackList || audioTrackList.length === 0) {
      throw new Error('No audio tracks available');
    }

    // change audio track

    const previousBuffer = player.buffered().length > 0 ? player.buffered().end(0) : 0;

    return new Observable(observer => {
      const trackChangeSubscription = this.trackChangeCompleted.subscribe(change => {
        if (change.trackId === audioTrackId && change.completed) {
          observer.next(change);
          observer.complete();
          trackChangeSubscription.unsubscribe();
        }
      });

      let trackChanged = false;
      for (const track of audioTrackList) {
        track.enabled = track.id === audioTrackId;
        if (track.enabled) {
          trackChanged = true;
          this.notification.notify(
            'custom',
            { message: `Track enabled: ${track.id} at ${new Date().toISOString()}` },
            10000
          );
        }
      }

      if (trackChanged) {
        // this.checkBufferAndComplete(player, audioTrackId, previousBuffer);
      } else {
        observer.next({ trackId: audioTrackId, completed: false, previousBuffer, newBuffer: 0 });
        observer.complete();
      }
    });
  }

  registerTrackChangeListener(player: any) {
    if (!player) {
      return;
    }

    const audioTrackList = player.tech().audioTracks();
    audioTrackList?.addEventListener('change', () => {
      for (const track of audioTrackList) {
        if (track.enabled) {
          const previousBuffer = player.buffered().length > 0 ? player.buffered().end(0) : 0;
          this.checkBufferAndComplete(player, track.id, previousBuffer);

          return;
        }
      }
      return;
    });
  }

  detectMultipleAudioTracks(player: any) {
    if (!player) {
      return;
    }
    const audioTrackList = player.tech().audioTracks();
    this.audioTrackList.next(audioTrackList.length > 1 ? audioTrackList.tracks_ : []);
  }
}
